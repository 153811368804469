<template>
  <div class="bg fullheight" fluid>
    <figure
      v-for="(bgImage, index) in bgImages"
      v-bind:key="index"
      v-bind:style="[{
        backgroundImage: 'url(' + bgImage.url + ')',
        animationDelay: index * (duration - transition) + 's'
      }]"
    >
    </figure>
    {{ bgLength }}
  </div>
</template>

<script>
var bgImageArray = [
  { url: "/img/20180722-DSC_3090_1200x801.jpg" },
  { url: "/img/20180722-DSC_2933_1200x801.jpg" },
  { url: "/img/20180722-DSC_2936_1200x801.jpg" },
  { url: "/img/20180722-DSC_2987_1200x801.jpg" },
  { url: "/img/20180722-DSC_2995_1200x801.jpg" },
  { url: "/img/20180722-DSC_3020_1200x801.jpg" },
  { url: "/img/20180722-DSC_3025_1200x801.jpg" },
  { url: "/img/20180722-DSC_3090-2_1200x801.jpg" }
];
var bgLength = bgImageArray.length;
var duration = 15;
var transition = 3;
export default {
  name: "MyBackground",
  data() {
    return {
      bgImages: bgImageArray,
      bgLength: bgLength,
      bgIndex: bgLength - 1,
      duration: duration,
      transition: transition
    };
  }
};
</script>

<style lang="scss" scoped>
$bgLength: 8;
$duration: 15;
$transition: 3;
$total: ($duration - $transition) * $bgLength;
$t_start: round(100 * ($transition / $total));
$t_end: round(100 * (($duration - $transition) / $total));
$t_finish: round(100 * ($duration / $total));

.bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
  width: 100vw;
  overflow: hidden;
  & > figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: transparent;
    backface-visibility: hidden;
    background-size: cover;
    background-position: center center;
    animation: imageCrossFade #{($duration - $transition) * $bgLength}s linear infinite
      0s;
    opacity: 0;
  }
  & > figure:first-child {
    opacity: 1;
  }
}

@keyframes imageCrossFade {
  @for $i from 0 through 100 {
    @if $i == 0 {
      #{$i}% {
        animation-timing-function: ease;
        opacity: 0;
      }
    }
    @if $i == $t_start {
      #{$i}% {
        animation-timing-function: ease;
        opacity: 1;
      }
    }
    @if $i == $t_end {
      #{$i}% {
        opacity: 1;
      }
    }
    @if $i == $t_finish {
      #{$i}% {
        opacity: 0;
      }
    }
    @if $i == 100 {
      #{$i}% {
        opacity: 0;
      }
    }
  }
}
</style>